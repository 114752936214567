import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotAdminGuard, AuthGuard, GuestGuard, MaintenanceGuard, NotMaintenanceGuard, NotStagingGuard, PendingGuard, StagingGuard, PendingEmailGuard } from '@fengbrasil/ngx-fengst-auth';
import { MaintenanceComponent, PageNotFoundComponent, InternalServerErrorComponent, LogoutComponent, TermsComponent, RecoveryPasswordComponent, StagingComponent, AdminComponent, ScailingMessageComponent, DesignSystemComponent } from '@fengbrasil/ngx-fengst-layout';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePublicModule),
    canActivate: [StagingGuard, GuestGuard, MaintenanceGuard],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePrivateModule),
    canActivate: [StagingGuard, AuthGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'home/:page',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePublicModule),
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'minha-conta/meu-plano',
    redirectTo: 'mi-cuenta/mi-plan',
    pathMatch: 'full'
  },
  {
    path: 'mi-cuenta/mi-plan',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.PlanModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'minha-conta/meu-perfil',
    redirectTo: 'mi-cuenta/mi-perfil',
    pathMatch: 'full'
  },
  {
    path: 'mi-cuenta/mi-perfil',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.ProfileModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'minha-conta/pagamentos',
    redirectTo: 'mi-cuenta/pagos',
    pathMatch: 'full'
  },
  {
    path: 'mi-cuenta/pagos',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.PaymentsModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'minha-conta/pagamentos/cartao-sucesso',
    redirectTo: 'mi-cuenta/pagos',
    pathMatch: 'full'
  },
  {
    path: 'mi-cuenta/pagos/cartao-sucesso',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.PaymentsModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'produtos-digitais',
    redirectTo: 'productos-digitales',
    pathMatch: 'full'
  },
  {
    path: 'productos-digitales',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.WelcomeKitModule),
    canActivate: [MaintenanceGuard, PendingGuard],
  },
  {
    path: 'carrinho',
    redirectTo: 'checkout/register',
    pathMatch: 'full'
  },
  { 
    path: 'checkout/register', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegisterModule),
    canActivate: [StagingGuard, MaintenanceGuard, GuestGuard],
  },
  { 
    path: 'checkout/success', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SuccessModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  { 
    path: 'checkout/subscribe/:idplano', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  // {
  //   path: 'checkout/resubscribe/:idplano/:idcontrato',
  //   loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.ResubscribeContractModule),
  //   canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  // },
  { 
    path: 'checkout/upgrade/:idplano', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.UpgradeModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  { 
    path: 'checkout/address', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.AddressModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  { 
    path: 'checkout/cart/:type/:idplano', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.CartModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard]
  },
  { 
    path: 'checkout/cart', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.CartModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'planos',
    redirectTo: 'planes',
    pathMatch: 'full'
  },
  {
    path: 'planes',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.PlansModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'planes/:type',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.PlansModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'pendente',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-auth').then((m) => m.PendingModule),
    canActivate: [StagingGuard, AuthGuard],
  },
  {
    path: 'validar-cadastro/:id',
    redirectTo: 'validar-registro/:id',
    pathMatch: 'full'
  },
  {
    path: 'validar-registro/:id',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.ValidateAccountModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'experiencias',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.CatalogModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'experiencias/como-pontuar',
    redirectTo: 'experiencias/como-puntuar',
    pathMatch: 'full'
  },
  {
    path: 'experiencias/como-puntuar',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.HowModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'experiencias/extrato',
    redirectTo: 'experiencias/extracto',
    pathMatch: 'full'
  },
  {
    path: 'experiencias/extracto',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.PointsModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'experiencias/resgatados',
    redirectTo: 'experiencias/canjeados',
    pathMatch: 'full'
  },
  {
    path: 'experiencias/canjeados',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.RescuesModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'ingressos',
    redirectTo: 'entradas',
    pathMatch: 'full'
  },
  {
    path: 'entradas',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-events').then((m) => m.EventsMainModule),
      canActivate: [StagingGuard, MaintenanceGuard, PendingGuard],
  },
  {
    path: 'atendimento',
    redirectTo: 'atencion-al-socio',
    pathMatch: 'full'
  },
  {
    path: 'atencion-al-socio',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-contact').then((m) => m.ContactModule),
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'termos-e-politicas',
    redirectTo: 'terminos-y-politicas',
    pathMatch: 'full'
  },
  {
    path: 'terminos-y-politicas',
    component: TermsComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [NotAdminGuard],
  },
  {
    path: 'staging',
    component: StagingComponent,
    canActivate: [NotStagingGuard],
  },
  {
    path: 'staging/login',
    component: StagingComponent,
    canActivate: [NotStagingGuard],
  },
  {
    path: 'recuperar-senha/:id',
    redirectTo: 'recuperar-contrasena/:id',
    pathMatch: 'full'
  },
  {
    path: 'recuperar-contrasena/:id',
    component: RecoveryPasswordComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'design-system',
    component: DesignSystemComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'sair',
    redirectTo: 'salir',
    pathMatch: 'full'
  },
  {
    path: 'salir',
    component: LogoutComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'alta-demanda',
    component: ScailingMessageComponent
  },
  {
    path: '500',
    component: InternalServerErrorComponent
  },
  {
    path: 'manutencao',
    component: MaintenanceComponent,
    canActivate: [NotMaintenanceGuard],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }