import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { AuthAPIService, AppService } from '@fengbrasil/ngx-fengst-auth';
import { LoadingService } from '@fengbrasil/ngx-fengst-ui';
import { ResizeService } from '@fengbrasil/ngx-fengst-ui';
import { combineLatest, Observable, ObservableInput, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { SubSink } from 'subsink';

declare let Bancard: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  private subsink = new SubSink();

  public isHome: Observable<boolean> = this.appService.isHome$;
  public isDependent: Observable<boolean> = this.appService.isDependent$;
  public isAuth: Observable<boolean> = this.authService.isAuth$['st'];
  public isAuthAdmin: Observable<boolean> = this.authService.isAuth$['admin'];
  public isAuthStaging: Observable<boolean> = this.authService.isAuth$['staging'];
  public isResale: Observable<boolean> = this.authService.isAuth$['resale'];
  public isLoading: Observable<boolean> = this.loadingService.isLoading$;

  title = 'ngx-feng';

  constructor(
    private resizeService: ResizeService,
    private loadingService: LoadingService,
    private authService: AuthAPIService,
    private appService: AppService,
    private router: Router,
    @Inject('fengEnv') public fengEnv: any,
    @Inject('fengSettings') public fengSettings: any
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    event.target.innerWidth;
    this.resizeService.setSize(event.target.innerWidth);
  }

  ngOnInit(): void {
    this.resizeService.setSize(window.innerWidth);
    
    this.subsink.add(
      this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        window.scrollTo(0, 0);
        this.appService.mainChecks(event.url);
        })
      )

    this.initBancard();
  }

  onRouterOutletActivate(e: any) {
  }

  async initBancard() {

    var styles = {
      'input-background-color' : '#453454',
      'input-text-color': '#B22222',
      'input-border-color' : '#CCCCCC',
      'input-placeholder-color' : '#999999',
      'button-background-color' : '#5CB85C',
      'button-text-color' : '#FFFFFF',
      'button-border-color' : '#4CAE4C',
      'form-background-color' : '#999999',
      'form-border-color' : '#DDDDDD',
      'header-background-color' : '#F5F5F5',
      'header-text-color' : '#333333',
      'hr-border-color' : '#B22222'
    };

    let options = {
      // styles: styles
    }

    // Bancard.Checkout.createForm('iframe-container', '[PROCESS_ID]', options);
    Bancard.Cards.createForm('iframe-container', 'gutu7P3zOJtCfZIoHjs4', options);
    // Bancard.Confirmation.loadPinPad('iframe-container', '[ALIAS_TOKEN]', options);
    // Bancard.Preauthorization.createForm('iframe-container', '[PROCESS_ID]', options);

  }
  
}
