export const fengTheme = {
    name: 'feng',
    properties: {
        
        '--background': '#FFFFFF',
        '--on-background': '#000',
        '--primary': '#104B90',
        '--primary-light': 'white',
        '--primary-RGB': '24, 129, 95',
        '--on-primary': '#fff',
        '--secondary': '#ED2028',
        '--secondary-RGB': '186, 148, 15',
        '--on-secondary': '#fff',
        '--surface': '#fff',
        '--surface-RGB': '255, 255, 255',
        '--on-surface': '#000',
        '--error': '#F25455',
        '--error-RGB': '242, 84, 85',
        '--on-error': '#fff',
        '--warning': '#EFC845',
        '--warning-RGB': '239, 200, 69',
        '--on-warning': '#fff',
        '--success': '#68DB6C',
        '--success-RGB': '104, 219, 108',
        '--on-success': '#fff',
        '--info': '#D0D0D0',
        '--info-RGB': '208, 208, 208',
        '--on-info': '#fff',

        '--max-width': '1200px',

        // Add external fonts @font-face to styles.scss
        '--font-primary': 'Roboto',
        '--font-title': 'Roboto',
        '--font-button': 'Roboto',
        '--font-event': 'Roboto',

        // Menu
        '--menu-itens-color': '--primary-light',
        '--menu-itens-background': 'transparent',
        '--menu-active-color': 'white',
        '--menu-active-background': '#6264AC',
        '--menu-active-underline': '0',

        '--menu-mobile-background': '#fff',
        '--menu-mobile-user-detail-color': '#404040',
        '--menu-mobile-user-detail-background': '#fff',
        '--menu-mobile-itens-color': '#404040',
        '--menu-mobile-itens-background': '#fff',

        // Header
        '--header-margin': '',
        // '--header-bg': 'url(https://cerro-st.s3.sa-east-1.amazonaws.com/prod/assets/img/footer-textura.png) no-repeat center center / cover',
        '--header-bg': '#104B90',
        // '--header-mobile-bg': 'url(https://fluminensefc.s3.amazonaws.com/prod/assets/images/header-flu.svg) no-repeat center center / auto',
        '--header-mobile-bg': '#104B90',
        '--header-mobile-logo-width': '104px',
        '--header-desktop-logo-width': '140px',
        '--header-mobile-height': '90px',
        '--header-desktop-height': '100px',
        '--header-mobile-font-size': '12px',
        '--header-counter-font-family': 'Roboto',
        '--header-counter-text-color': '#FFF',
        '--header-counter-number-font-family': 'Roboto',
        '--header-counter-number-outline-color': 'none',
        '--header-desktop-font-size': '14px',
        '--header-mobile-margin-top': '0',
        '--header-desktop-margin-top': '0',
        '--header-mobile-logo-top': '-20px',
        '--header-desktop-logo-top': '-20px',
        '--public-header-security-top-padding': '0',
        '--private-header-security-top-padding': '0',
        '--header-counter-background': '#FFFFFF',
        '--header-counter-number-bg': '#104B90',
        '--header-logged-bg': '#104B90',
        '--header-logged-mobile-logo-width': '100px',
        '--header-logged-desktop-logo-width': '140px',

        // Home Public
        // Call to Action
        '--home-call-to-action-background': 'no-repeat center right / cover',
        '--home-call-to-action-background-img': 'url(https://cerro-st.s3.sa-east-1.amazonaws.com/prod/assets/img/2024/site/bg-hero.png)',
        '--home-call-to-action-font-family': 'Athletic-Pro-Bold',
        '--home-call-to-action-background-opacity': '1',
        '--home-call-to-action-detail-color': '#fff',
        '--home-call-to-action-title-color': '#ED2028',
        '--home-call-to-action-title-font-size': '75px',
        '--home-call-to-action-title-font-family': 'Athletic-Pro-Bold',
        '--home-call-to-action-title-desktop-max-width': '870px',
        '--home-call-to-action-title-font-weight': 'bold',
        '--home-call-to-action-title-margin': '0 auto',
        '--home-call-to-action-title-line-height': '120%',
        '--home-call-to-action-title-alternate-font-family': 'Athletic-Pro-Bold',
        '--home-call-to-action-title-alternate-font-size': '96px',
        '--home-call-to-action-subtitle-font-size': '26px',
        '--home-call-to-action-subtitle-line-height': '28px',
        '--home-call-to-action-subtitle-width': '800px',
        '--home-call-to-action-content-padding': '2% 0',
        '--home-call-to-action-content-align': 'center',
        '--home-call-to-action-button-margin': '37px',
        '--home-call-to-action-button-color': '#FFF',
        '--home-call-to-action-button-background': '#ED2028',
        '--home-call-to-action-hover-button-color': '#FFF',
        '--home-call-to-action-button-padding': '15px 64px',
        '--home-call-to-action-button-font-size': '19px',
        '--home-call-to-action-button-font-family': 'Ernesto',
        '--home-call-to-action-button-style': 'normal',
        '--home-call-to-action-margin-button': '50px 0 0',
        '--home-call-to-action-9-primary-font-family': 'Roboto',
        '--home-call-to-action-9-primary-button-font-weight': '700',
        '--home-call-to-action-9-description-font-size': '50px;',
        '--home-call-to-action-9-descriptionn-line-height': '60px',
        '--home-call-to-action-9-description-text-align': 'start',
        '--mobile-home-call-to-action-title-margin': '0 auto',
        '--mobile-home-call-to-action-content-padding': '0 10% 50px',
        '--mobile-home-call-to-action-title-font-size': '40px',
        '--mobile-home-call-to-action-title-line-height': '120%',
        '--mobile-home-call-to-action-title-alternate-font-family': 'Athletic-Pro-Bold',
        '--mobile-home-call-to-action-title-alternate-font-size': '62px',
        '--call-to-action-margin-top': '0',
        '--call-to-action-padding-top': '30px',

        // Tabs
        '--home-desktop-tabs-1-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-1-background-img': '#F8F8F8',
        '--home-desktop-tabs-2-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-2-background-img': '#F8F8F8',
        '--home-desktop-tabs-3-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-3-background-img': '#F8F8F8',
        '--home-desktop-tabs-9-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-9-background-bg': 'url(https://www.fengbrasil.com.br/assets/images/particle.png)',
        '--home-desktop-tabs-9-background-bg-shield': 'url(https://www.fengbrasil.com.br/assets/images/particle.png)',
        '--home-desktop-tabs-9-background-bg-flag':'url(https://www.fengbrasil.com.br/assets/images/particle.png)',
        '--home-desktop-tabs-9-button-color': '#104B90',
        '--home-desktop-tabs-9-title-font-family': 'Roboto',
        '--home-desktop-tabs-9-primary-font-family': 'Roboto',
        '--home-desktop-tabs-9-primary-button-font-weight': '500',
        '--home-desktop-tabs-9-description-font-size': '50px;',
        '--home-desktop-tabs-9-descriptionn-line-height': '0',
        '--home-desktop-tabs-9-description-text-align': 'center',
       

        // Home Private
        '--home-private-padding-top': '0',
        '--home-private-featured-height-tablet': '248px',
        '--home-private-featured-height-mobile-410': '133px',
        '--home-private-featured-height-mobile-380': '122px',
        '--home-private-plan-box-title-color': '#104B90',
        '--home-private-cards-title': '#104B90',

        // Plans
        '--plans-background': '#f8f8f8',

        // My Profile
        '--my-profile-title-color': '#104B90',
        '--my-profile-name-color': '#104B90',
        '--my-profile-subtitle-color': '#104B90',
        '--my-profile-card-font-weight': '300',
        '--my-profile-text': '#27292D',
        '--my-profile-button': '#104B90',
        '--my-profile-header': '#FFE794',

        // Contact
        '--contact-background': '#104B90',
        '--panel-header-title-color': 'var(--primary)',

        // Experiences
        '--experience-how-to-score-icon-filter': 'brightness(0) saturate(100%) invert(18%) sepia(96%) saturate(1538%) hue-rotate(199deg) brightness(96%) contrast(94%)',

        // Exclusive Content
        '--exclusive-content-background-RGB': '0,0,0',
        '--exclusive-content-background': 'white',
        '--exclusive-content-text': 'var(--primary)',

        // Events
        '--event-background-select': '#6264AC',
        '--event-background-table': '#f8f8f8',
        '--event-color-button': '#104B90',
        '--event-color-button-primary': '#104B90',
        '--event-color-button-secondary': '#104B90',
        '--event-transparent-button': '#104B90',
        '--event-button-border-color': '#f8f8f8',
        '--event-color-text-button': '#ffffff',
        '--event-color-text-default': '#f8f8f8',
        '--event-color-text-light': '#f8f8f8',
        '--event-color-text-dark': '#104B90',
        '--event-color-text-primary': '#000',
        '--event-color-text-secondary': '#104B90',

        // Footer
        '--footer-item-margin': '25px auto',
        '--footer-color': '#ffffff',
        '--footer-background': 'url(https://cerro-st.s3.sa-east-1.amazonaws.com/prod/assets/img/footer-textura.png) no-repeat center center / cover',
        '--footer-logo-width': '120px',

        // Auth Modal
        '--auth-modal-stlogo-width': '120px',
        '--auth-modal-stlogo-bg': '#104B90',
        '--auth-modal-closebtn-color': 'black',
        '--mobile-auth-modal-closebtn-color': 'white',
        '--mobile-auth-modal-stlogo-width': '100px',
        '--mobile-auth-modal-stlogo-margin': '10px 0',
        '--first-access-bg': '#104B90',

        // Pending E-mail
        '--pending-mail-bg': 'white',
        '--pending-mail-color': 'black',
        '--pending-mail-logo-height': '100px',
        '--pending-mail-logo-bg': '#104B90',

        // Staging
        '--staging-logo-background-color': '#104B90',

        // Maintenance
        '--maintenance-logo-background-color': '#104B90',

        // Register
        '--register-v2-background': 'white',
    },
    images: {
        'stLogo': 'https://cerro-st.s3.sa-east-1.amazonaws.com/prod/assets/img/2024/site/logos-cerro/logo-cerrista.svg',
        'stLogoMobile': 'https://cerro-st.s3.sa-east-1.amazonaws.com/prod/assets/img/2024/site/logos-cerro/logo-cerrista.svg',
        'stLogoFooter': 'https://cerro-st.s3.sa-east-1.amazonaws.com/prod/assets/img/2024/site/logos-cerro/logo-cerrista.svg',
        'loadingLogo': 'https://cerro-st.s3.sa-east-1.amazonaws.com/prod/assets/img/2024/site/logos-cerro/logo-cerrista.svg',
        'stShield': 'https://cerro-st.s3.sa-east-1.amazonaws.com/prod/assets/img/2024/site/logos-cerro/logo-cerro.svg ',
        '--homePlansBG': 'https://cerro-st.s3.sa-east-1.amazonaws.com/prod/assets/img/2024/site/bg-planos.png',
        '--homeTicketsBG': '#104B90',
        '--homeExclusiveContentBG': '#104B90',
        'mailPendingLogo': 'https://cerro-st.s3.sa-east-1.amazonaws.com/prod/assets/img/2024/site/logos-cerro/logo-cerrista.svg',
    },
    texts: {
        // Home
        // Call to Action
        'callToActionFeatured': '<strong>PASIÓN, ORGULLO Y TRADICIÓN</strong>',
        'callToActionFeaturedImg': '',
        'callToActionDetail': 'Hacete socio y disfrutá de nuevos planes y más beneficios.',
        'callToActionButton': 'ASOCIATE',

        // Auth
        // First Access
        'firstAccessText': 'Identificamos que este es su primer acceso a nuestro nuevo sistema. Para continuar, debes aceptar los Términos de uso y la Política de privacidad. Aprovecha también para actualizar tus datos de registro!',
        // Recover Pass
        'recoverPassText': 'Para establecer su nueva contraseña, ingrese el código de recuperación recibido por correo electrónico.',

        // Ticket Cards
        'ticketCardText': 'Carnet de Socio',
        'ticketCardsText': 'CARNET DE SOCIO'
    }
}
