export const fengEnv = {
  production: false,
  maintenance: false,
  apiURL: 'https://api.dev.cerro.feng.rocks/v1/',
  weURL: 'https://we.dev.cerro.feng.rocks/WE',
  recaptcha: {
    key: '6LfPERopAAAAAES4T-qekSe2xmMrc64UVVXo8itf',
  },
  auth: {
    social: [
    ],
    cognito: {
      enable: false
    }
  },
  activeProject: 'CERRO'
};